<template>
  <el-dialog
    width="530px"
    :title="title"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="80px" :model="paramsForm" :rules="rules">
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="paramsForm.name" placeholder="请输入企业名称" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="系统别称" prop="appName">
        <el-input v-model="paramsForm.appName" placeholder="请输入系统别称" style="width: 400px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleColse">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postNewEnterprise, postEditEnterprise } from '@/api/super-admin/enterprise-mgr.js';

export default {
  data() {
    return {
      visible: false,
      title: '新增',
      paramsForm: {
        id: '',
        name: '',
        appName: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符之内', trigger: 'blur' }
        ],
        appName: [
          { required: true, message: '请输入系统别称', trigger: 'blur' },
          { max: 20, message: '长度在 20 个字符之内', trigger: 'blur' }
        ]
      },
      confirmLoading: false
    };
  },
  methods: {
    open(data) {
      this.visible = true;
      if (!data) {
        return (this.title = '新增');
      }

      this.title = '编辑';
      this.$nextTick(() => {
        const { id, orgName, appName } = data;
        this.paramsForm.id = id;
        this.paramsForm.name = orgName;
        this.paramsForm.appName = appName;
      });
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.paramsForm.id = '';
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;

        if (this.paramsForm.id) {
          this.onPostEditEnterprise(this.paramsForm);
        } else {
          this.onPostNewEnterprise(this.paramsForm);
        }
      });
    },
    onPostNewEnterprise(data) {
      this.confirmLoading = true;
      postNewEnterprise(data)
        .then(() => {
          this.$message.success('新增企业成功！');
          this.handleColse();
          this.$emit('on-success');
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    onPostEditEnterprise(data) {
      this.confirmLoading = true;
      postEditEnterprise(data)
        .then(() => {
          this.$message.success('编辑企业成功！');
          this.handleColse();
          this.$emit('on-success', data.id);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    }
  }
};
</script>
