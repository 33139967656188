<template>
  <div>
    <!-- query nav -->
    <el-form ref="queryFormRef" :inline="true" :model="queryForm" label-width="80px">
      <el-form-item prop="searchName">
        <el-input v-model="queryForm.searchName" placeholder="请输入企业名称/姓名/电话搜索" style="width:280px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- table -->
    <el-button type="primary" icon="el-icon-plus" style="margin-bottom: 16px" @click="handleAdd">新增</el-button>
    <el-table v-loading="tableLoading" :data="enterpriseList">
      <el-table-column fixed align="center" width="80" type="index" label="序号"></el-table-column>
      <el-table-column
        fixed
        align="center"
        width="300"
        prop="orgName"
        label="企业名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column align="center" width="200" prop="createTime" label="入驻时间"></el-table-column>
      <el-table-column align="center" width="300" label="系统别称" show-overflow-tooltip>
        <template slot-scope="{ row }">{{ row.appName || '--' }}</template>
      </el-table-column>
      <el-table-column align="center" width="200" label="管理员姓名" show-overflow-tooltip>
        <template slot-scope="{ row }">{{ row.name || '--' }}</template>
      </el-table-column>
      <el-table-column align="center" width="200" label="管理员电话" show-overflow-tooltip>
        <template slot-scope="{ row }">{{ row.phone || '--' }}</template>
      </el-table-column>
      <el-table-column align="center" width="150" label="状态">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.status"
            active-color="#13ce66"
            :active-value="1"
            inactive-color="#ff4949"
            :inactive-value="2"
            @change="onToggleStatus($event, row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" width="200" label="操作">
        <template slot-scope="{ row }">
          <div>
            <span class="action-btn mr-24" @click="handleEdit(row)">修改</span>
            <span class="action-btn" @click="handleGrant(row)">分配管理员</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>
    <!-- 新增/编辑弹窗 -->
    <enterprise-dialog ref="enterpriseDialogRef" @on-success="onSuccess"></enterprise-dialog>
    <!-- 分配管理员弹窗 -->
    <admin-dialog ref="adminDialogRef" @on-success="getEnterpriseListData"></admin-dialog>
  </div>
</template>

<script>
// import { getItem } from '@/utils/storage.js';
import EnterpriseDialog from './enterpriseDialog.vue';
import AdminDialog from './adminDialog.vue';
import { getEnterpriseList, toggleStatus } from '@/api/super-admin/enterprise-mgr.js';

export default {
  components: { EnterpriseDialog, AdminDialog },
  data() {
    return {
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        searchName: ''
      },
      tableLoading: false,
      enterpriseList: []
    };
  },
  created() {
    this.getEnterpriseListData();
  },
  methods: {
    getEnterpriseListData() {
      this.tableLoading = true;
      getEnterpriseList(this.queryForm)
        .then(res => {
          this.total = res.data.total;
          this.enterpriseList = res.data.records;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getEnterpriseListData();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getEnterpriseListData();
    },
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getEnterpriseListData();
    },
    handleReset() {
      this.$refs.queryFormRef.resetFields();
      this.handleSearch();
    },
    handleAdd() {
      this.$refs.enterpriseDialogRef.open();
    },
    handleEdit(row) {
      this.$refs.enterpriseDialogRef.open(row);
    },
    onSuccess(id) {
      if (!id) {
        this.handleReset();
      } else {
        this.getEnterpriseListData();
      }
    },
    onToggleStatus(status, row) {
      const { id, appId } = row;
      toggleStatus({ id, appId, status })
        .then(res => {
          this.$message.success(`${status === 1 ? '激活' : '冻结'}企业成功！`);
        })
        .catch(() => {
          // 更改状态失败，刷新列表
          this.getEnterpriseListData();
        });
    },
    handleGrant(row) {
      this.$refs.adminDialogRef.open(row);
    }
  }
};
</script>

<style lang="less" scoped>
</style>
