<template>
  <el-dialog
    width="530px"
    title="分配管理员"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleColse"
  >
    <el-form ref="form" inline label-width="100px" :model="paramsForm" :rules="rules">
      <el-form-item label="企业名称" prop="orgName">
        <el-input disabled v-model="paramsForm.orgName" placeholder="请输入企业名称" style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="分配管理员" prop="userIdsList">
        <el-select
          v-model="paramsForm.userIdsList"
          filterable
          multiple
          placeholder="请选择或输入以搜索"
          style="width: 380px"
        >
          <el-option
            v-for="item in userList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleColse">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getGrantUserList, postGrantUser } from '@/api/super-admin/enterprise-mgr.js';

export default {
  data() {
    return {
      visible: false,
      paramsForm: {
        orgName: '',
        orgId: '',
        userIdsList: []
      },
      rules: { userIdsList: [{ required: true, message: '请选择管理员', trigger: 'change' }] },
      userList: [],
      confirmLoading: false
    };
  },
  methods: {
    open(row) {
      const { id: orgId, orgName } = row;
      this.visible = true;
      this.$nextTick(() => {
        this.paramsForm.orgId = orgId;
        this.paramsForm.orgName = orgName;
        this.getGrantUserListData(orgId);
      });
    },
    getGrantUserListData(orgId) {
      getGrantUserList({ orgId }).then(res => {
        this.userList = res.data;
        this.paramsForm.userIdsList = [];
        res.data.forEach(item => {
          if (item.checked) {
            this.paramsForm.userIdsList.push(item.userId);
          }
        });
      });
    },
    handleColse() {
      this.visible = false;
      this.$refs.form.resetFields();
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        this.confirmLoading = true;
        postGrantUser(this.paramsForm)
          .then(res => {
            this.$message.success('分配管理员成功！');
            this.handleColse();
            this.$emit('on-success');
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    }
  }
};
</script>
