import request from '@/utils/request.js';

/**
 * 获取企业列表
 * @returns { Promise }
 */
export function getEnterpriseList(params) {
  return request({
    url: '/traffic-construction/org/list',
    method: 'get',
    params
  });
};
/**
 * 新增企业
 * @returns { Promise }
 */
export function postNewEnterprise(data) {
  return request({
    url: '/traffic-construction/org/add',
    method: 'post',
    data
  });
};

/**
 * 编辑企业
 * @returns { Promise }
 */
export function postEditEnterprise(data) {
  return request({
    url: '/traffic-construction/org/edit',
    method: 'post',
    data
  });
};

/**
 * 切换状态
 * @returns { Promise }
 */
export function toggleStatus(data) {
  return request({
    url: '/traffic-construction/org/enableOrDisableOrg',
    method: 'post',
    data
  });
};

/**
 * 获取管理员下拉列表
 * @returns { Promise }
 */
export function getGrantUserList(params) {
  return request({
    url: '/traffic-construction/sysUser/getGrantUser',
    method: 'get',
    params
  });
};

/**
 * 为企业设置管理员
 * @returns { Promise }
 */
export function postGrantUser(data) {
  return request({
    url: '/traffic-construction/sysUser/grantOrgUser',
    method: 'post',
    data
  });
};
